<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha">Alunos</h2>
      </div>
      <div class="col-md-2" v-if="situacaoAno">
        <pm-Button
          type="button"
          v-if="acesso.cadastrar"
          style="float: right; margin-top: 30px"
          @click="registar()"
          label="CADASTRAR NOVO ALUNO"
          class="p-button-success"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="item"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="p-fluid formgrid grid" style="font-size: 12px">
            <h5 style="font-size: 14px; margin-top: 10px">Buscar Alunos:</h5>
            <div class="field col-12 md:col-4 lg:col-3" style="margin: 0px">
              <span class="p-input-icon-left">
                <pm-InputText
                  v-model="pesquisa"
                  @keyup.enter="pesquisar"
                  placeholder="Nome"
                />
              </span>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin: 0px">
              <span class="p-input-icon-left">
                <pm-InputText
                  v-model="pesquisa_sobrenome"
                  @keyup.enter="pesquisarSobrenome"
                  placeholder="Sobrenome"
                />
              </span>
            </div>
          </div>
        </template>
        <pm-Column
          field="foto"
          header="FOTO DE PERFIL"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <div class="tb-center" v-if="data.foto">
              <img
                v-if="data.foto"
                class="img-perfil-dtb-pq"
                :src="base_url + '/storage/' + data.foto"
                @click="fotoOpen(data.foto, 'A')"
              />
            </div>
            <div class="tb-center" v-if="data.foto == null">
              <pm-Avatar
                icon="pi pi-user"
                class="mr-2"
                style="background-color: #e1e1e1; color: #ffffff"
                shape="circle"
              />
            </div>
          </template>
        </pm-Column>
        <pm-Column
          field="nome"
          sortable
          header="NOME DO ALUNO"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            {{ data.nome + " " + data.sobrenome + " " }}
            &nbsp;
            <span
              class="badge bg-danger table_style"
              v-if="data.deficiencia == 1"
              :title="data.qual_deficiencia"
              >NEE</span
            >
          </template>
        </pm-Column>
        <pm-Column
          field="nome_mae"
          sortable
          header="NOME DA MÃE"
          style="font-size: 14px; vertical-align: middle !important"
        ></pm-Column>
        <pm-Column
          field="cpf"
          sortable
          header="CPF"
          style="font-size: 14px; vertical-align: middle !important"
        ></pm-Column>
        <pm-Column
          field=""
          header="SITUAÇÃO"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template
            #body="{ data }"
            v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7"
          >
            <va-badge
              v-if="data.situacao == 0"
              class="table_style"
              :text="'INATIVO'"
              :color="'#f50000'"
              title="Click para Ativar o Aluno."
              @click="mudarSituacao(data.id)"
              style="cursor: pointer"
            />
            <va-badge
              v-if="data.situacao == 1"
              class="table_style"
              :text="'ATIVO'"
              :color="'#09b163'"
              title="Click para Desativar o Aluno."
              @click="mudarSituacao(data.id)"
              style="cursor: pointer"
            />
          </template>
          <template #body="{ data }" v-else>
            <va-badge
              v-if="data.situacao == 0"
              class="table_style"
              :text="'INATIVO'"
              :color="'#f50000'"
            />
            <va-badge
              v-if="data.situacao == 1"
              class="table_style"
              :text="'ATIVO'"
              :color="'#09b163'"
            />
          </template>
        </pm-Column>
        <pm-Column
          field=""
          sortable
          class="table_style"
          header="TURMA DO ALUNO"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <a
              @click="irPgAluno(data.turma)"
              v-if="data.turma != 'sem turma'"
              style="cursor: pointer"
            >
              <va-badge
                v-if="data.turma.nome"
                class="table_style"
                :text="data.turma.nome"
                :color="'#6c757d'"
              />
            </a>
            <div v-else>
              <va-badge :text="'SEM TURMA'" :color="'#fba92d'" />
            </div>
          </template>
        </pm-Column>
        <pm-Column
          field="id"
          headerStyle="width: 34rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible;width: 477px;vertical-align:middle !important"
        >
          <template #body="{ data }">
            <pm-Button
              type="button"
              @click="fotos(data)"
              icon="pi pi-camera"
              label=""
              title="Foto de Perfil"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px"
              v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7"
            >
            </pm-Button>

            <pm-Button
              type="button"
              @click="loginmodal(data)"
              icon="pi pi-users"
              label=""
              title="Atualizar Login"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px"
              v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7"
            >
            </pm-Button>

            <pm-Button
              type="button"
              @click="editarAluno(data.id)"
              icon="pi pi-user-edit"
              label=""
              title="Editar dados dos alunos(CPF, nome, email, etc.)"
              v-if="situacaoAno && acesso.editar"
              class="p-button-sm btn-color table_style"
            ></pm-Button>

            <pm-Button
              title="Adicionar arquivos importantes sobre o aluno."
              type="button"
              v-if="acesso.cadastrar"
              @click="addArquivo(data.id)"
              label=""
              icon="pi pi-upload"
              class="p-button-success p-button-sm btn-color table_style"
              style="margin-left: 5px"
            ></pm-Button>

            <pm-Button
              title="Verificar o histórico do aluno."
              type="button"
              v-if="acesso.visualizar"
              @click="historicoAluno(data.id)"
              label=""
              icon="pi pi-history"
              class="p-button-warning p-button-sm btn-color table_style"
              style="margin-left: 5px"
            ></pm-Button>

            <pm-Button
              title="Apagar as informações cadastradas do aluno."
              type="button"
              v-if="acesso.deletar"
              @click="deletarAluno(data.id)"
              label=""
              icon="pi pi-trash"
              class="p-button-danger p-button-sm btn-color table_style"
              style="margin-left: 5px"
            >
            </pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages" style="display: inline-flex">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <va-modal v-model="showModal" hide-default-actions overlay-opacity="0.2">
      <slot>
        <div>
          <img :src="foto_grande" style="width: 400px" />
        </div>
      </slot>
    </va-modal>

    <va-modal
      v-model="showModalLogin"
      hide-default-actions
      overlay-opacity="0.2"
    >
      <slot>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <br />
            <h5>Acesso ao sistema:</h5>
          </div>
          <div
            class="field col-12 md:col-12"
            v-if="temlogin == 0"
            style="
              background-color: #00d7f9;
              padding: 15px;
              color: #fff;
              border-radius: 5px;
              margin-bottom: 20px;
            "
          >
            <span class="label label-info"> Aluno não possui Login!</span>
          </div>
          <div class="field col-12 md:col-12">
            <label for="firstname">Email:</label>
            <pm-InputText
              id="email"
              :style="greenColor"
              v-model="info.email"
              v-on:blur="verificarEmail"
            />
            <small v-show="emailInvalidoJaExiste" class="p-error"
              >O Email digitado já está em uso!</small
            >
          </div>
          <div class="field col-12 md:col-12">
            <label for="senha">Senha:</label>
            <pm-Password
              v-model="info.senha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
              toggleMask
              :class="{ 'p-invalid': validationErrors.senha && submitted }"
            ></pm-Password>
            <small v-show="validationErrors.senha" class="p-error"
              >As Senhas não coincidem!</small
            >
          </div>
          <div class="field col-12 md:col-12">
            <label for="senha">Confirmar senha:</label>
            <pm-Password
              v-model="info.confirmarSenha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
              toggleMask
              :class="{
                'p-invalid': validationErrors.confirmarSenha && submitted,
              }"
            ></pm-Password>
            <small v-show="validationErrors.confirmarSenha" class="p-error"
              >Senhas não coincidem!</small
            >
          </div>
          <div class="field col-4 md:col-4">
            <pm-Button
              type="button"
              @click="AtualizarLogin()"
              icon="pi pi-user-edit"
              label="Atualizar Login"
              title="Atualizar Login"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px"
            >
            </pm-Button>
          </div>
          <div class="field col-4 md:col-4" v-if="this.temlogin == 1">
            <pm-Button
              title="Apagar o login do aluno."
              type="button"
              @click="deletarLogin()"
              label="Deletar Login"
              icon="pi pi-trash"
              class="p-button-danger p-button-sm btn-color table_style"
              style="margin-left: 5px"
            >
            </pm-Button>
          </div>
        </div>
      </slot>
    </va-modal>

    <!--Modals-->
    <va-modal
      v-model="showMediumModal"
      :title="$t(tituloModal)"
      :message="$t(textoModal)"
      position="top"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Aluno } from "@/class/alunos.js";
import { Verificar } from "@/class/verificar.js";
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Util from "@/utils/rota";

export default defineComponent({
  props: {
    pg: {
      default: 1,
    },
  },
  data() {
    const columns = [
      { key: "codigo_INEP", label: "Codigo INEP" },
      { key: "nome", label: "Nome", sortable: true },
      { key: "nome_mae", label: "Mãe", sortable: true },
      { key: "cpf" },
      { key: "rg" },
      { key: "deficiencia" },
      { key: "id", label: "Ação" },
    ];

    return {
      funcao: 0,
      showModalLogin: false,
      showModal: false,
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Alunos" }],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nome: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      situacaoAno: 1,
      filter: "",
      showMediumModal: false,
      tituloModal: "",
      textoModal: "",

      item: [],
      links: [],
      columns,
      perPage: 50,
      currentPage: 1,
      pagina_atual: 1,
      linha: 12,
      adc_turma: false,
      addturma: {
        ano: 0,
      },
      pesquisa: null,
      pesquisa_sobrenome: null,
      foto_grande: null,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },

      nome_aluno: "",
      validationErrors: {},
      emailInvalidoJaExiste: "",
      info: {
        id: "",
        email: "",
        senha: "",
        confirmarSenha: "",
        emailDele: "",
      },
      id: null,
      temlogin: 1,
    };
  },

  async beforeMount() {
    await this.logado();
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.listarTudo();

    if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
      let acesso = await Verificar.AcessoLocal(8);
      this.acesso.visualizar = acesso.visualizar;
      this.acesso.cadastrar = acesso.cadastrar;
      this.acesso.editar = acesso.editar;
      this.acesso.deletar = acesso.deletar;
    } else {
      let acesso = await Verificar.AcessoLocal(9);
      this.acesso.visualizar = acesso.visualizar;
      this.acesso.cadastrar = acesso.cadastrar;
      this.acesso.editar = acesso.editar;
      this.acesso.deletar = acesso.deletar;
    }
  },
  methods: {
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    async loginmodal(data) {
      this.info.id = "";
      this.info.email = "";
      this.info.senha = "";
      this.info.confirmarSenha = "";
      this.id = null;
      this.nome_aluno = data.nome + " " + data.sobrenome;
      this.showModalLogin = true;
      this.id = data.id;
      this.info.id = data.id;
      const dt = await Aluno.obtemUm(this.id);
      if (dt.data[0].login) {
        this.info.email = dt.data[0].login.email;
        this.info.emailDele = dt.data[0].login.email;
      }

      if (dt.data[0].login.length == 0) {
        this.temlogin = 0;
      } else {
        this.temlogin = 1;
      }
      console.log(dt);
    },
    async AtualizarLogin() {
      //const data = await Aluno.obtemUm(this.id);
      const data = await Aluno.alterarLogin(this.info);
      this.showModalLogin = false;
      this.$vaToast.init({
        message: "Login Atualizado com sucesso!",
        iconClass: "fa-star-o",
        position: "top-right",
        duration: 2500,
        fullWidth: false,
      });
      this.listarTudo();
    },
    async deletarLogin() {
      const data = await Aluno.deletarLogin(this.info);
      this.showModalLogin = false;
      this.$vaToast.init({
        message: "Login Deletado com sucesso!",
        iconClass: "fa-star-o",
        position: "top-right",
        duration: 2500,
        fullWidth: false,
      });
    },
    irPgAluno(turma) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "turma-add-aluno",
          params: {
            etapa_id: turma.segmento_id,
            periodo_id: turma.serie_id,
            turma_id: turma.id,
            escola_id: turma.escola_id,
            turno: turma.turno,
            ativa: turma.ativa,
          },
        });
      } else {
        this.$router.push({
          name: "turma-add-aluno-coordenacao",
          params: {
            etapa_id: turma.segmento_id,
            periodo_id: turma.serie_id,
            turma_id: turma.id,
            escola_id: turma.escola_id,
            turno: turma.turno,
            ativa: turma.ativa,
          },
        });
      }
    },

    async mudarSituacao(id) {
      let data = await Aluno.mudarSituacaoAluno(id);
      if (
        data.data.erro ==
        "Aluno está em uma Turma este ano você deve ir na Turma e Dizer qual é o motivo, de Desativa-lo!"
      ) {
        alert(
          "Aluno está em uma Turma este ano você deve ir na Turma e Dizer qual é o motivo, de Desativa-lo!"
        );
      } else {
        this.listarTudo();
      }
    },
    fotoOpen(foto, sexo) {
      //quando a foto vier Null eu mando os Sexos 'F' ou 'M'
      //caso nao tiver null eu mando um 'A'

      if (sexo == "A") {
        this.foto_grande = this.base_url + "/storage/" + foto;
        this.showModal = true;
      }
    },
    async pesquisar() {
      if (this.pesquisa != null && this.pesquisa != "") {
        let data = await Aluno.pesqAlunoCompleto(this.pesquisa);
        this.item = data.data;
        this.links = [];
      } else {
        this.listarTudo();
      }
    },
    async pesquisarSobrenome() {
      if (this.pesquisa != null && this.pesquisa != "") {
        let data = await axios.get(
          "/alunos/v2/" +
            this.pesquisa +
            "/sobrenome/" +
            this.pesquisa_sobrenome +
            "/0"
        );
        this.item = data.data;
        this.links = [];
      } else {
        this.listarTudo();
      }
    },
    async paginas(n) {
      if (n.url != null) {
        if (!n.active) {
          let numero = n.url.split("?page=");
          this.pagina_atual = numero[1];

          if (numero == 1) {
            let res = await Aluno.obtemTodos(this.currentPage);
            this.item = [];
            this.item = res.data.data;
            this.links = res.data.links;
          } else {
            this.currentPage = this.pagina_atual;
            if (
              (typeof n === "object" || typeof n === "function") &&
              n !== null
            ) {
              let res = await axios.get(n.url);
              this.item = [];
              this.item = res.data.data;
              this.links = res.data.links;
            } else {
              let res = await Aluno.obtemTodos(this.currentPage);
              this.item = [];
              this.item = res.data.data;
              this.links = res.data.links;
            }
          }
        }
      }
    },
    editarAluno(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "aluno-basic-info-editar",
          params: { id: id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "aluno-basic-info-cadastrar-do-aluno-coordenacao-editar",
          params: { id: id, pg: this.currentPage },
        });
      }
    },
    registar() {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "aluno-basic-info-cadastrar" });
      } else {
        this.$router.push({
          name: "aluno-basic-info-cadastrar-do-aluno-coordenacao",
        });
      }
    },
    async fotos(data){
      //console.log(data);
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "fotos",
          params: { id: data.id, pg: this.currentPage },
        });
      }else{
        this.$router.push({
          name: "fotos-coord",
          params: { id: data.id, pg: this.currentPage },
        });
      }
    },
    async adicionarAlunoTurma(id) {
      try {
        if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
          this.$router.push({
            name: "alunoaddturma",
            params: { id: id, pg: this.currentPage },
          });
        } else {
          this.$router.push({
            name: "alunoaddturma",
            params: { id: id, pg: this.currentPage },
          });
        }
      } catch (e) {}
    },
    async historicoAluno(id) {
      try {
        if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
          this.$router.push({
            name: "alunohistorico",
            params: { id: id, pg: this.currentPage },
          });
        } else {
          this.$router.push({
            name: "coordenacao-alunos-hist",
            params: { id: id, pg: this.currentPage },
          });
        }
      } catch (e) {
        //  console.log(e);
      }
    },
    addArquivo(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "alunoArquivo",
          params: { id: id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "coordenacao-alunos-arq",
          params: { id: id, pg: this.currentPage },
        });
      }
    },
    async deletarAluno(id) {
      if (window.confirm("Você realmente deseja apagar o aluno?")) {
        let data = await Aluno.deletarAluno(id);
        this.listarTudo();
      }
    },
    async listarTudo() {
      try {
        this.currentPage = this.pg;
        this.item = [];
        let data = await Aluno.obtemTodos(this.currentPage);
        this.item = data.data.data;
        this.links = data.data.links;
        //this.item.reverse();
      } catch (e) {}
    },
    async verificarEmail() {
      if (this.info.email == "" || this.info.email == null) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
        return false;
      }

      let data = await Verificar.verificarJaExisteEmail(this.info.email);
      try {
        if (this.info.id == undefined) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else if (
          this.info.id != undefined &&
          this.info.email !== this.info.emailDele
        ) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else {
          this.emailInvalidoJaExiste = false;
          this.greenColor = "";
        }
      } catch (e) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
      }
    },
    validateForm() {
      var ok = this.info.senha === this.info.confirmarSenha;

      if (!ok) this.validationErrors["senha"] = true;
      else delete this.validationErrors["senha"];

      if (!ok) this.validationErrors["confirmarSenha"] = true;
      else delete this.validationErrors["confirmarSenha"];

      if (
        this.info.conectividade == "1" &&
        (this.info.tipos_dispotivos == "" ||
          this.info.tipos_dispotivos == undefined)
      )
        this.validationErrors["tipos_dispotivos"] = true;
      else delete this.validationErrors["tipos_dispotivos"];
      return !Object.keys(this.validationErrors).length;
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.pm-card {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
</style>
